@import '../../App.css';

.divClassListFirstRow {
    position: relative;
}

.btnNewClassText {
    margin: 0 auto;
    display: block;
}

.iconArchive {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
    color: var(--primary-color);
    transition: all 0.5s ease;
}

.iconArchived {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--primary-color);
}

.iconArchive:hover {
    color: var(--secondary-color);
    transition: all 0.5s ease;
}

.iconArchiveSelected {
    color: var(--secondary-color);
}

.divClassList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.divClassItem {
    min-width: 250px;
    text-align: center;
    /*border: 2px solid var(--primary-color);*/
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px var(--primary-color);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    animation: showItem 0.5s ease-in-out;
}

.divClassItem-remove {
    animation: removeItem 0.5s ease-in-out;
}



.className,
.schoolName {
    color: var(--primary-color);
    transition: all 0.3s ease;
    cursor: pointer;
}



.schoolName {}

.invited {
    color: var(--secondary-color);
}

/* Mobile */
@media (max-width: 767px) {
    
}

/* Tablet and computer */
@media (min-width: 768px) {
    .divClassItem:hover {
        box-shadow: 0 0 5px var(--secondary-color);
        /*border: 2px solid var(--secondary-color);*/
        transition: all 0.3s ease;
    }

    .divClassItem:hover>.className,
    .divClassItem:hover>.schoolName {
        color: var(--secondary-color);
        transition: all 0.3s ease;
    }

}