@import '../../../App.css';

body {}


.divAdminSummary {
    width: 90%;
    margin-inline: auto;
}

.divAdminSummaryUsers,
.divAdminSummaryClasses,
.divAdminSummaryFruits
 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-block: 20px;
    align-items: center;
    text-align: center;
}

.divAdminSummaryUsers div,
.divAdminSummaryClasses div {
    box-shadow: 0 0 10px var(--primary-color);
    padding: 10px;
    text-align: center;
    border-radius: 10px;
}

.divAdminSummaryUsers div p,
.divAdminSummaryUsers div ul,
.divAdminSummaryClasses div p
 {
    font-size: var(--font-size-heading);
    margin: 0;
}

.divAdminSummaryUsers div ul {
    list-style: none;
    padding: 0;
}

.divAdminSummaryFruits div {
    max-width: 600px;

}
