@import '../App.css';


.divSelector {

    width: 100%;
    text-align: center;
}
.divDropdown {
    position: relative;
}

.divDropdown-header {
    /* background-color: var(--background-color);
    border: 1px solid #ccc;
    cursor: pointer; */
    width: -webkit-fill-available;
    font-size: var(--font-size-body);
}

.divDropdown-menu {
    position: absolute;
    z-index: 1000;
    background-color: var(--background-color); 
    list-style: none;
    width: -webkit-fill-available;
    margin: 0 5px 5px 5px;
    padding: 0px;
    border-top: none;
    border: 1px solid var(--primary-color);
}

.divDropdown-menu li {
    cursor: pointer;
    font-size: var(--font-size-body);
}

.divDropdown-menu li:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.divDropdown .arrow {
    float: right;
    transition: transform 0.3s ease;
}

.divDropdown .arrow.open {
    transform: rotate(180deg);
}