@import '../App.css';

.divNews {
    padding: 20px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    gap: 30px;
    justify-content: center;
}

.divNews .divNew {
    box-shadow: 0 0 10px var(--primary-color);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    width: 500px;
    max-width: -webkit-fill-available;
}

.divNews .divNewIsNew .divNewIcon {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: var(--secondary-color);
    padding: 10px;
    border-radius: 50%;
    font-weight: bold;
    color: white;
    transform: rotateZ(-25deg)
}

.divNews .divNew h1 {
    text-transform: uppercase;
}

.divNews .divNew p {
    font-size: var(--font-size-subheading);
    margin-top: 15px;
}