@import '../App.css';

.divPodium {
    margin-inline: 20px;
    border: 1px solid var(--secondary-color);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.divPodium h2 {
    color: var(--secondary-color);
}
.divPodiumBar {
    display: flex;
    justify-content: center;
    max-height: 500px;
}

.divPodiumBar canvas {

}

.divPodiumTop3 {
    text-align: center;
    margin-top: 10px;
}

.divPodiumTop3 div {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.podiumPosition {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
}

.divPodiumTop3 div:nth-child(1) .podiumPosition {
    color: #ffd700;
}

.divPodiumTop3 div:nth-child(2) .podiumPosition {
    color: #c0c0c0;
}

.divPodiumTop3 div:nth-child(3) .podiumPosition {
    color: #cd7f32;
}





/* Mobile */
@media (max-width: 767px) {

    .divPodium {
        width: calc(100vw - 80px);
    }
}
