@import '../App.css';

.divUserNotEnabled {

}
.warningUserNotEnabledYet {
    color: var(--error-color);
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    margin: 10px auto;
}

.btnSendActivationEmailAgain {
    margin-inline: auto;
    display: block;
    background-color: var(--error-color);
    border-color: var(--error-color);
    margin-bottom: 30px;
}
.btnSendActivationEmailAgain:hover {
    color: var(--error-color);
    background-color: var(--background-color);
}

.divVScroll {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        overflow-y: scroll;
    
}