@import '../App.css';


.divMessage {
    padding: 10px;
    width: fit-content;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--background-color);
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.divMessageError {
    background-color: var(--error-color);
}
.divMessageWarning {
    background-color: var(--warning-color);
}
.divMessageOK {
    background-color: var(--okey-color);
}
.divMessage p {
    margin-left: 20px;
}


.divMessageHide {
  display: none;
}