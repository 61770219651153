@import '../App.css';

.loginDivOutside {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
}

.loginDivBackground {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.loginDivBackgroundOutside {
    width: 100%;
    height: 100vh;
    position: relative;
}
.loginDivBackgroundOutside img {
    position: absolute;
}
.loginDivInside {
    display: grid;
    grid-template-columns: 2fr 3fr;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 20px grey;
    background-color: var(--background-color);
}

.loginDivImg {
    margin: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
}

.loginDivForm {
    margin: 0 10px;
}

.loginImg {
    height: 200px;
    margin: 0 auto;
}

.loginForm {
    display: grid;
    grid-template-columns: 1fr;
}

.loginError {
    color: red;
}

.loginDivPassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
}

.loginDivPassword input {
    width: 100%;
}
.loginEyeIcon {
    color: var(--primary-color);
} 

.loginInput {
    position: relative;
    margin-top: 15px;
}
.loginInput input {
    width: -webkit-fill-available;
}
.loginInput label {
    position: absolute;
    top: 0;
    left: 0;
    translate: 10px 5px;
    pointer-events: none;
    transition: translate 250ms;
}

.loginInput ::placeholder {
    color: transparent;
}

.loginInput:focus-within label,
.loginInput:not(:has(:placeholder-shown))
label {
    translate: 10px -20px;
    
}

.btnLoginGoogle {
    line-height: var(--font-size-heading)
}
.btnLoginGoogle .iconLoginGoogle {
    font-size: var(--font-size-heading);
}

.loginLink {
    cursor: pointer;
}
@media (max-width: 767px) {
    .loginDivForm label {
        /*display: none;*/
    }
    .loginImg {
        height: 100px;
    }
    .loginDivInside {
        grid-template-columns: 1fr;
    }
}