@import '../App.css';

.divUserModal {
    width: 100%;
    position: relative;
}
.updateUserError {
    color: var(--error-color);
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
}
.divUserFields {
    width: fit-content;
    margin: 0 auto;
}

.divUserField {
    margin: 20px;
    display: flex;
    max-width: 300px;
    justify-content: flex-start;
    align-items: center;
}

.divUserField p {
    width: 100px;
}

.divUserBtns {
    display: flex;
    justify-content: center;
}

.loginEyeIcon {
    color: var(--primary-color);
} 

