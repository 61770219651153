@import '../../App.css';

.memoryfruits {
    text-align: center;
}

.memoryfruits input {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
}

.memoryfruits .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.memoryfruits .card {
    padding: 3px;
    box-shadow: 0 0 10px var(--primary-color);
    margin: 5px;
    border-radius: 5px;
}

.memoryfruits .card img {
    width: -webkit-fill-available;
    max-width: 80%;
    margin-top: 10%;
}

.memoryfruits .cardRotate {
    transform: rotateY(180deg);
    transition: transform 0.8s;
}

.memoryfruits .win_title {
    font-size: var(--font-size-title);
    color: var(--secondary-color);
}

.memoryfruits .flip-card {}

.memoryfruits .flip-card-inner {
    position: relative;

    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.memoryfruits .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.memoryfruits .flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.memoryfruits .flip-card-front {
    z-index: 2;
    transform: rotateY(0deg);
}

.memoryfruits .flip-card-back {
    transform: rotateY(180deg);
}

.memoryfruits p {
    margin-bottom: 10px;
}