@import '../../App.css';

.fruitzzle {
    text-align: center;
    max-width: 100vw;
}

.fruitzzle_score_time {
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: var(--font-size-heading);
    align-items: center;
    margin-bottom: 15px;
}

.fruitzzle_score {
    width: var(--font-size-heading);
    height: var(--font-size-heading);
    line-height: var(--font-size-heading);
    text-align: center;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding: 10px;
}

.fruitzzle_time {
    border: 2px solid var(--secondary-color);
    color: var(--secondary-color);
    padding: 10px;
    width: 60px;
}

.fruitzzle_div_input {
    display: flex;
    margin-inline: auto;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.fruitzzle_score_final_div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: var(--font-size-heading);
    color: var(--primary-color);
    box-shadow: 0 0 10px var(--secondary-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    width: fit-content;
    margin-inline: auto;
}

.fruitzzle_score_final {
    width: var(--font-size-title);
    height: var(--font-size-title);
    line-height: var(--font-size-title);
    padding: 10px;
    font-size: var(--font-size-title);
    background-color: var(--secondary-color);
    color: var(--background-color);
    border-radius: 50%;
    font-weight: bold;
}

.fruitzzle_div_letters {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.fruitzzle_letter_name,
.fruitzzle_letter_random {
    padding: 10px;
    margin: 10px;
    font-size: var(--font-size-title);
    border-radius: 10px;
    color: var(--primary-color);
    box-shadow: 0 0 10px var(--primary-color);
    width: var(--font-size-title);
    text-align: center;
    height: var(--font-size-title);
    line-height: var(--font-size-title);
    cursor: pointer;
}
.fruitzzle_letter_name {
    color: var(--background-color);
}

.fruitzzle_letter_selected {
    box-shadow: 0 0 10px var(--secondary-color);
}

.fruitzzle_letter_wrong {
    box-shadow: 0 0 10px var(--error-color);
    color: var(--primary-color);
}

.fruitzzle_letter_correct {
    box-shadow: 0 0 10px var(--okey-color);
    color: var(--primary-color);
}


.fruitzzle_letter_random {
    
}