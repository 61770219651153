@import '../../App.css';

body {}

.divStudent {
    width: 250px;
    text-align: center;
    /*border: 2px solid var(--primary-color);*/
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px var(--primary-color);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    animation: showItem 0.5s ease-in-out;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    padding-right: 20px;
}

.divStudent-remove {
    animation: removeItem 0.5s ease-in-out;
}


.divStudentFirstRow {
    text-align: center;
}

.divStudentFirstRow>img {
    width: 65px;
    border-radius: 25px;
}

.divStudentMeals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.divStudentMeals img {
    width: 40px;
}


.divStudentPoints {
    display: flex;
    justify-content: space-around;
    gap: 5px;
}

.divStudentPoints h3 {
    color: var(--primary-color);
}

.divStudentPoints p {
    font-size: calc(var(--font-size-body) - 3px);
    color: var(--primary-color);
}

.studentName {
    color: var(--primary-color);
    transition: all 0.3s ease;
    margin: 0;
}


.divMeal {
    position: relative;
    animation: showItem 0.5s ease-in-out;
}

.btnDeleteMeal {
    display: none;
}

.divMeal-remove {
    animation: removeItem 0.5s ease-in-out;
}

.divStudentTotalPoints h3 {
    font-size: var(--font-size-title);
    padding: 5px;
    border-radius: 50%;
    width: var(--font-size-title);
    margin: 0;
    line-height: var(--font-size-title);
    /* box-shadow: 0 0 5px var(--primary-color);
    color: var(--background-color);
    background-color: var(--primary-color); */
    color: var(--secondary-color)
}

.imagAvatarStudentBox {
    border: 2px solid transparent;
    transition: all 0.5s ease;
}

.imagAvatarStudentBox:hover {
    border: 2px solid var(--secondary-color);
    transition: all 0.5s ease;
}

/* Mobile */
@media (max-width: 767px) {

    .btnDeleteStudent {
        top: calc(var(--size-icon) + 8px);
    }
}

/* Tablet and computer */
@media (min-width: 768px) {

    .divStudent:hover {
        box-shadow: 0 0 5px var(--secondary-color);
        /*border: 2px solid var(--secondary-color);*/
        transition: all 0.3s ease;
    }

    .divStudent:hover>.studentName {
        color: var(--secondary-color);
        transition: all 0.3s ease;
    }

    .divMeal:hover .btnDeleteMeal {
        display: block;
        padding: 0;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        color: var(--secondary-color);
        font-size: calc(var(--size-icon) - 10px);
        filter: drop-shadow(0 0 2px black)
    }

    .divMeal:hover .btnDeleteMeal:hover {
        color: var(--primary-color);
        transition: all 0.5s ease;
    }

}