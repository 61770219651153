@import '../../App.css';

.games {
    width: 100%;
}
.games .head {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.games .head button {
    font-size: var(--font-size-heading);
}

.games .gameSelected {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.divGameClose {
    position: relative;
}

.gameCloseIcon {
    margin-left: 20px;
    font-size: var(--font-size-title);
    background-color: var(--primary-color);
    color: var(--background-color);
    border-radius: 50%;
    padding: 5px;
    width: var(--font-size-title);
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}
.gameCloseIcon:hover {
    transition: all 0.5s ease;
    background-color: var(--secondary-color);
}

.gameImg {
    max-width: 200px;
    padding: 10px;
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 10px var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
}

.gameImg:hover {
    border-color: var(--secondary-color);
    box-shadow: 0 0 10px var(--secondary-color);
    transition: all 0.5s ease;
}

@media (max-width: 767px) {
    .gameCloseIcon {
        font-size: var(--font-size-heading);
        width: var(--font-size-heading);
    }
}