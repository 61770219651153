@import '../../App.css';

body {}

.divStats {
    overflow-y: scroll;
    width: 100%;
    margin-bottom: 30px;
}

.divStats h1:nth-of-type(2) {
    margin-top: 50px;
}

.divStatsStudents,
.divStatsFruits {
    display: grid;
}

.divStatsStudents {
    margin-bottom: 20px;
}

.divStats h1 {
    margin-bottom: 20px;
}


/* Mobile */
@media (max-width: 767px) {

    .divStatsStudents,
    .divStatsFruits {
        grid-template-columns: 1fr;
    }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {

    .divStatsStudents,
    .divStatsFruits {
        grid-template-columns: 1fr;
    }
}

/* Computer  */
@media (min-width: 1023px) {

    .divStatsStudents,
    .divStatsFruits {
        grid-template-columns: 1fr 1fr 1fr;
    }
}