@import '../App.css';

.divAwards {
    margin-bottom: 20px;
}
.tableAwards {
    max-width: 800px;
    margin: 0 auto;
    border-spacing: 10px;
    border-collapse: separate;
}

.trAward, .trTitle {
    display: grid;
    grid-template-columns: 5fr 5fr 1fr 1fr 1fr;
}


.tdAwardTitle, .tdAwardPoints {
    padding: 5px;
    padding: 5px;
    border: 1px solid lightgray;
    margin: 5px;
    border-radius: 5px;
}

.tdTitlePoints {
    text-align: center;
}

.tdAwardEdit, .tdAwardDelete {
    text-align: center;
    cursor: pointer;
}

.tdAwardPoints {
    text-align: center;
}

.tdAwardPoints input {
    text-align: center;
}
.pNoAwards {
    text-align: center;
}

.divNewAwardInputs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.iconAwardType {
    height: -webkit-fill-available;
    margin: 7px;
    color: var(--primary-color);
}

.divNewAwardBtn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.divGeneratePoster {
    text-align: center;
}

.divSortAwards {
    margin-block: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: fit-content;
    margin-inline: auto;
}

.awardIconSort {
    padding-inline: 20px;
    font-size: var(--font-size-heading);
    color: var(--primary-color);
}

.awardIconSortSelected {
    color: var(--secondary-color);
    transition: all 0.5s ease;
}

.awardIconSort:hover {
    color: var(--secondary-color);
    transition: all 0.5s ease;

}

/* Computer styles */
@media (min-width: 768px) {
   
    
}