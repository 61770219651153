@import '../../../App.css';

body {}


.divAdminNew {
    width: 80%;
    box-shadow: 0 0 10px var(--primary-color);
    margin-inline: auto;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.divAdminAddNew {
    width: fit-content;
    margin-inline: auto;
    text-align: center;
    margin-bottom: 30px;
}

.divAdminAddNew div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
}

.divAdminAddNew div input,
.divAdminAddNew div textarea {
    width: 100%;
}