@import '../../App.css';

body {}

.divAdmin table {
    width: 100%;
}

.divAdmin thead tr{
    background-color: var(--primary-color);
    color: var(--background-color);
}

.divAdmin thead tr th {
    padding: 10px;
}

.divAdmin tbody tr td {
    text-align: center;
}

.divAdmin tbody tr:hover {
    background-color: var(--secondary-color);
}

.divAdmin tbody .trSelected {
    background-color: var(--secondary-color);
}

.divAdminOptions .react-tabs {
    width: 100%;
  }
  
  .divAdminOptions  .react-tabs__tab-list {
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .divAdminOptions  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: all .25s ease-in-out;
  }
  
  .divAdminOptions  .react-tabs__tab--selected {
    color: var(--secondary-color);
    border: 1px solid transparent;
    border-bottom: 2px solid var(--secondary-color);
    background-color: #fff;
    font-weight: bold;
  }
  
  .divAdminOptions  .react-tabs__tab--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .divAdmin {
    width: 100%;
  }

  .divAdminOptions .react-tabls__tab-panel {

    width: 100vw;
    overflow-x: auto;
  }

  .divAdmin input[type="checkbox"] {
    accent-color: var(--secondary-color);
  }