@import '../../App.css';

.divStudentViewGeneral {
    width: 100%;
}
.divStudentView {
    padding: 10px;
    width: fit-content;
    margin-inline: auto;
}

.btnUpdateAvatar {
    display: block;
    margin-inline: auto;
}

.imgStudentViewAvatar {
    display: block;
    width: 200px;
    border: 4px solid transparent;
    border-radius: 15px;
    transition: all 0.5s ease;
    cursor: pointer;
}

.imgStudentViewAvatar:hover {
    border: 4px solid var(--secondary-color);
    transition: all 0.5s ease;

}


.divStudentViewAvatarList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.imgAvatarList {
    width: 200px;
    border: 4px solid transparent;
    border-radius: 15px;
    transition: all 0.5s ease;
}

.imgAvatarList:hover {
    border-color:var(--secondary-color);
    transition: all 0.5s ease;
}

.imgAvatarListSelected {
    border-color:var(--primary-color);
    transition: all 0.5s ease;
}
.divStudentViewFirstRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.divStudentViewPoints {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    text-align: center;
}

.studentNameStudentView {
    margin-bottom: 20px;
}
.divStudentViewOptions {
    display: flex;
    justify-content: center;
}

.divStudentViewFruits {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.divStudentViewFruit {

    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    box-shadow: 0 0 5px grey;
    border-radius: 10px;
    padding: 10px;
}

.divStudentViewFruit img {
    width: 50px;
}

.divStudentViewAwards {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.divStudentViewAward {
    padding: 10px;
    box-shadow: 0 0 10px var(--primary-color);
    border-radius: 10px;
    text-align: center;    
}

.divStudentViewAward h2 {
    margin-bottom: 5px;
    border-bottom: 1px solid var(--primary-color);
}

@media (max-width: 767px) {
    
}