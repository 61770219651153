@import '../App.css';

.divMenu {

}
.menuNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    border-bottom: 2px solid var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
    position: sticky;
    top: 0;
    background-color: var(--background-color);
    margin-bottom: 10px;
}

.menuNavTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary-color);
    gap: 10px;
    width: 100%;
}
.menuNavTitleLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.menuClassName {
    font-size: var(--font-size-heading);
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding: 5px;
    border-radius: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.menuTitle {
    font-size: var(--font-size-title);
    margin: 0;
    padding: 0;
}

.menuLogo {
    height: var(--font-size-title);
}


.menuIcon {
    font-size: var(--size-icon);
    color: var(--primary-color);
    transition: all 0.5s ease;
    width: 35px;
    cursor: pointer;
}

.divMenuNavOptionsOutside {
    position: relative;
    z-index: 1;
}

.divMenuClasses {
    max-height: 300px;
    overflow-y: auto;
}

.divMenuNavOptions {
    position: absolute;
    left: 0;
    z-index: 1;
    width:var(--menu-width);
    background-color: var(--background-color);
    box-shadow: 0 0 5px var(--primary-color);
    animation: showMenu 0.3s linear;
    overflow-y: auto;
}

.divMenuNavOptionsHide {
    animation: hideMenu 0.3s linear;
}

.menuIconLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 10px;
    padding: 10px;
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
}

.menuIconLink p {
    cursor: pointer;
}

.menuIconLink:hover > .menuIconItem,
.menuIconLink:hover > .menuIconText {
    color: var(--secondary-color);
    transition: all 0.5s ease;
}

.menuIconItem {
    font-size: var(--size-icon);
    color: var(--primary-color);
    transition: all 0.5s ease;
    width: 35px;
}

.menuIconLinkClass .menuIconItem{
    margin-left: 20px;
    font-size: calc(var(--size-icon) - 4px);
}

.menuClassName {

}

.menuLink {
    text-decoration: none;
}

.menuItemNews {
    position: relative;
}

.menuItemNumberNews {
    position: absolute;
    top: -5px;
    left: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    padding: 3px;
    color: var(--background-color);
    font-weight: bold;
    text-align: center;
}

.menuItemNumberNewsBar {
    top: 5px;
    left: 0px;
    width: 10px;
    height: 10px;
    line-height: 10px;
}

@keyframes showMenu {
    from {
        transform: translateX(var(--menu-width-neg));
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes hideMenu {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(var(--menu-width-neg));
    }
}


/* MOBILE */
@media (max-width: 767px) {
    .divMenuNavOptions {
        top: 59px;
        height: calc(100% - 59px);
    }

    .menuIconLink {
        gap: 10px;
        margin: 5px;
        padding: 5px;
    }
    .menuIconText {
        font-size: var(--font-size-subsubheading);
    }

    .menuItemNumberNews {
        width: 10px;
        height: 10px;
        line-height: 10px;
    }
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1023px) {
    .menuItemNumberNews {
        width: 10px;
        height: 10px;
        line-height: 10px;
        top: 8px;
    }
}
/* TABLET AND PC */
@media (min-width: 767px) {
    .divMenuNavOptions {
        top: 71px;
        height: calc(100% - 71px);
    }

    .menuIcon:hover {
        color: var(--secondary-color);
    }
    

}