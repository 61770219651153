:root {

  /** COLORS **/
  --background-color: #FEFEFE;
  --text-color: #24292e;
  --text-color-inverse: white;
  --primary-color: #535353;
  --primary-color-hover: #686767;
  --secondary-color: #e8998d;
  --error-color: #fc3a3a;
  --warning-color: #e2a657;
  --okey-color: #7edd76;

  /** SIZES **/
  --font-size-title: 40px;
  --font-size-heading: 22px;
  --font-size-subheading: 18px;
  --font-size-subsubheading: 17px;
  --font-size-body: 14px;
  --font-size-body-small: 12px;
  --size-icon: 28px;

  /** WIDTHS **/
  --menu-width: 250px;
  --menu-width-neg: -250px;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}



.App {
  text-align: center;
}

/** HEADINGS **/
h1 {
  color: var(--primary-color);
  font-size: var(--font-size-heading);
  text-align: center;
  margin: 0;
  cursor: default;
}

h2 {
  color: var(--primary-color);
  font-size: var(--font-size-subheading);
  text-align: center;
  margin: 0;
  cursor: default;
}

h3 {
  color: var(--primary-color);
  font-size: var(--font-size-subsubheading);
  margin: 0;
  cursor: default;
}

p {
  font-size: var(--font-size-body);
  margin: 0;
  cursor: default;
}

.bold {
  font-weight: bold;
}

.pError {
  font-size: calc(var(--font-size-body) - 2px);
}

/** BUTTONS **/
button {
  padding: 5px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  margin: 5px;
  transition: all 0.5s ease;
  font-weight: bold;
  cursor: pointer;
}

.buttonDisabled {
  opacity: 0.5;
}


.buttonInverse {
  background-color: var(--background-color);
  color: var(--primary-color);
  transition: all 0.5s ease;
}


/** LINKS **/
a {
  color: var(--primary-color);
}

/** ICONS **/
.iconFruitity {
  font-size: var(--font-size-heading);
  padding: 5px;
  cursor: pointer;
}

.iconFruititySmall {
  font-size: var(--font-size-body);
  padding: 2px;
}


/** FORMS **/
label {
  color: var(--primary-color);
  margin: 5px;
}

input, textarea, .divDropdown-header {
  color: var(--primary-color);
  padding: 5px;
  /* border-radius: 5px; */
  border-color: var(--primary-color);
  border-style: solid;
  border-top: none;
  border-right: none;
  margin: 5px;
  transition: all 0.5s ease;
  box-shadow: 0 0 4px black;
  border-bottom: 2px solid transparent;
  background-color: var(--background-color);
}

input:focus, textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--primary-color);
  transition: all 0.5s ease;
}

input, textarea {
  font-family: 'Montserrat', sans-serif;
  accent-color: var(--secondary-color);
}

::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-year-field:focus {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.divDropdown-header {
  padding: 5px;
  margin: 5px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}
/*
::-webkit-datetime-edit { padding: 1em; }
::-webkit-datetime-edit-fields-wrapper { background: silver; }
::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; }
::-webkit-datetime-edit-month-field { color: blue; }
::-webkit-datetime-edit-day-field { color: green; }
::-webkit-datetime-edit-year-field { color: purple; }
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { background: orange; }
*/
/* CALENDAR */
::selection {
  background-color: var(--primary-color);
  color: var(--background-color);
}


/** SCROLLS **/

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--background-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color-hover);
}

*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px var(--primary-color);  */
  border-radius: 8px;
}

/** ANIMATIONS **/
@keyframes showItem {
  from {
    opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes removeItem {
  from {
    opacity: 1;
  }
  to {
      opacity: 0;
  }
}

/* Mobile styles */
@media (max-width: 767px) {

  :root {
    --font-size-title: 30px;
    --font-size-heading: 18px;
    --font-size-subheading: 16px;
    --font-size-subsubheading: 14px;
    --font-size-body: 11px;
    --font-size-body-small: 11px;
    --size-icon: 18px;

  }
}

/* Tablet and computer */
@media (min-width: 768px) {
  button:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    transition: all 0.5s ease;
  }


  .buttonInverse:hover {
    background-color: var(--primary-color);
    color: var(--background-color);
    border-color: var(--background-color);
    transition: all 0.5s ease;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  /* ... */
}

/* Computer styles */
@media (min-width: 1024px) {
  /* ... */
}