@import '../App.css';

.divCookies {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 1000;
}