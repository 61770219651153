@import '../../App.css';

.divNewClass {
    text-align: center;
}



.divNewClassStudent label {

}
