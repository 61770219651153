@import '../App.css';

.divModalOutside {
    position: absolute;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    left: 0;
}

.divModalInside {
    background-color: var(--background-color);
    width: fit-content;
    padding: 35px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid var(--primary-color);
    position: relative;
    box-shadow: 0 0 20px black;  
    animation: slideInUp 0.5s linear;

}
.divModalButtons {
    margin-top: 20px;
}
.btnModalClose {
    position: absolute;
    top: 0;
    right: 0;
}

.divModalInputs {
  margin-top: 10px;
}

.modalError {
  color: red;
}

.toggleSwitch {
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.toggleSwitchSelected {
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--background-color);
  transition: all 0.5s ease;
}

.toggleSwitchUnselected {
  background-color: var(--background-color);
  color: var(--primary-color);
  transition: all 0.5s ease;
}

.toggleSwitchSelected,
.toggleSwitchUnselected {
  
  padding: 5px;
  cursor: pointer;
}


@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}