@import '../App.css';

.buymeacoffee-div {
    text-align: center;
}
.buymeacoffee-btn a{
    color: var(--background-color);
    text-decoration: none;
}

.buymeacoffee-btn a:hover{
    color: var(--primary-color);
}

.buymeacoffee-div img {
    max-width: 300px;
}

.buymeacoffee-description2 {
    font-weight: bold;
    text-transform: uppercase;
}