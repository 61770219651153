@import '../App.css';

.loginPinDivOutside {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
}

.loginPinDivBackground {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.loginPinDivBackgroundOutside {
    width: 100%;
    height: 100vh;
    position: relative;
}
.loginPinDivBackgroundOutside img {
    position: absolute;
}
.loginPinDivInside {
    display: grid;
    grid-template-columns: 2fr 3fr;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 20px grey;
    background-color: var(--background-color);
}

.loginPinDivImg {
    margin: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
}

.loginPinDivForm {
    margin: 0 10px;
}

.loginPinImg {
    height: 200px;
    margin: 0 auto;
}

.loginPinForm {
    display: grid;
    grid-template-columns: 1fr;
}

.loginPinError {
    color: red;
}

.loginPinInput {
    position: relative;
    margin-top: 15px;
}
.loginPinInput input {
    width: -webkit-fill-available;
}
.loginPinInput label {
    position: absolute;
    top: 0;
    left: 0;
    translate: 10px 5px;
    pointer-events: none;
    transition: translate 250ms;
}

.loginPinInput ::placeholder {
    color: transparent;
}

.loginPinInput:focus-within label,
.loginPinInput:not(:has(:placeholder-shown))
label {
    translate: 10px -20px;
    
}

.btnloginPinGoogle {
    line-height: var(--font-size-heading)
}
.btnloginPinGoogle .iconloginPinGoogle {
    font-size: var(--font-size-heading);
}

.loginPinLink {
    cursor: pointer;
}

.loginUserPassword {
    cursor: pointer;
}
@media (max-width: 767px) {
    .loginPinDivForm label {
        /*display: none;*/
    }
    .loginPinImg {
        height: 100px;
    }
    .loginPinDivInside {
        grid-template-columns: 1fr;
    }
}