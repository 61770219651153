@import '../App.css';

.divLoading {
    position: absolute;    
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.divLoading img {
    width: 75px;
    height: 75px;
    animation: rotate 2s linear infinite;
}
/* */
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }