@import '../../App.css';


.divNewClassStudents {
    margin-top: 15px;
    text-align: center;
}


@media (max-width: 767px) {
    .divNewClassStudent label {
        display: inline-block;
        width: 100%;
    }
}