@import '../../App.css';

.bombfruit {
    text-align: center;
    max-width: 100vw;
}

.bombfruit_word,
.bombfruit_keyboard,
.bombfruit_used_letters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.bombfruit_word {
    margin-top: 20px;
}
.bombfruit_word,
.bombfruit_keyboard {
    cursor: pointer;
}
.bombfruit_image_div {
    margin-bottom: 20px;
    position: relative;
    width: 150px;
    margin-inline: auto;
}

.bombfruit_image_div p {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    font-size: var(--font-size-title);
    color: var(--background-color);
    font-weight: bold;
}

.bombfruit_keyboard {
    max-width: 800px;
    margin-inline: auto;
}

.bombfruit_letter,
.bombfruit_key {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--primary-color);
    font-weight: bold;
    color: var(--primary-color);
}

.bombfruit_letter_win {
    box-shadow: 0 0 10px var(--okey-color);
}

.bombfruit_letter_lose {
    box-shadow: 0 0 10px var(--error-color);
}

.bombfruit_key_correct {
    background-color: var(--okey-color);
    color: var(--background-color);
}

.bombfruit_key_wrong {
    background-color: var(--error-color);
    color: var(--background-color);
}

.bombfruit_img {
    max-width: 150px;
}