@import '../../App.css';

.catchfruits {
    width: 90%;
    margin: 0 auto;
}

.catchfruits canvas {
    width: 100%;
    height: calc(100vh - 180px);
    display: block;
}