@import '../App.css';

.signupDivOutside {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
}

.signupDivBackground {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.signupDivBackgroundOutside {
    width: 100%;
    height: 100vh;
    position: relative;
}
.signupDivBackgroundOutside img {
    position: absolute;
}

.signupDivInside {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border: 2px solid var(--primary-color);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 20px grey;
    width: 50%;
    background-color: var(--background-color);
    /*max-height: 80%;*/
}

.signupDivImg {
    margin: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    width: 33%;
}

.signupDivForm {
    margin: 0 10px;
    width: 66%;
}

.signupImg {
    height: 200px;
    margin: 0 auto;
}

.signupForm {
    display: grid;
    grid-template-columns: 1fr;
}

.signupError {
    color: red;
}

.signupDivForm label {
    /*display: none;*/
}

.signupDivPassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
}

.signupDivPassword input {
    width: 100%;
}
.signupEyeIcon {
    color: var(--primary-color);
} 

.signupInput {
    position: relative;
    margin-top: 15px;
}
.signupInput input {
    width: -webkit-fill-available;
}
.signupInput label {
    position: absolute;
    top: 0;
    left: 0;
    translate: 10px 5px;
    pointer-events: none;
    transition: translate 250ms;
}

.signupInput ::placeholder {
    color: transparent;
}

.signupInput:focus-within label,
.signupInput:not(:has(:placeholder-shown))
label {
    translate: 10px -20px;
    
}
.btnSignupGoogle {
    line-height: var(--font-size-heading)
}
.btnSignupGoogle .iconSignupGoogle {
    font-size: var(--font-size-heading);
}

.signupLink {
    cursor: pointer;
}

.signupText {
    color: var(--secondary-color);
    font-weight: bold;
}
/* TABLET */
@media (min-width: 768px) and (max-width: 1023px) {
    .signupDivInside {
        width: 60%;
    }
  }

/* MOBILE */
@media (max-width: 767px) {
    .signupDivForm label {
        /*display: none;*/
    }
    .signupImg {
        height: 100px;
    }
    .signupDivInside {
        display: grid;
        grid-template-columns: 1fr;
        width: 80%;
    }

    .signupDivImg {
        width: 100%;
        margin: 0;

    }
    .signupDivForm {
        width: 100%;
        margin: 0;
    }
}