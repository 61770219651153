@import '../App.css';


.divCalendar {
    width: fit-content;
    margin: 0 auto;
}

.divCalendar>input,
.divCalendar>button {
    font-size: var(--font-size-subheading);
}

.react-datepicker__tab-loop {
    position: absolute;
}

.react-datepicker {
    background-color: #fff;
    border: 1px solid #ccc;
  }
  
  .react-datepicker__day--selected {
    background-color: var(--secondary-color) !important;
    color: white;
  }

  .react-datepicker__day--selected:hover {
    background-color: var(--primary-color) !important;
  }