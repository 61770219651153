@import '../../App.css';

.divSetupClass {
    padding-inline: 20px;
}
.divSetupClassModals {
    width: 100%;
    position: relative;
}
.divSetupClass h1 {
    margin-bottom: 20px;
}

.divSetupClassName {
    display: grid;
    grid-template-columns: 6fr 10fr;
    gap: 20px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.iconEditNameClass, .iconEditNameSchool {
    color: var(--primary-color);
    transition: all 0.5s ease;
} 

.divSetupClassUsersInvited {
    margin-bottom: 20px;
}
.divSetupClassUsersInvited button {
    display: block;
    margin-inline: auto;
}
.divSetupClassUsersInvited h2 {
    margin-bottom: 10px;
}

.divSetupClassUsersInvitedPInvite {
    margin-top: 15px;
}
.divSetupClassUserInvited {
    display: grid;
    grid-template-columns: 10fr 1fr;
    gap: 20px;
    margin-bottom: 10px;
}

.divBtnsEditArchiveClass {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.btnSetupClassDeleteClass {
    display: block;
    margin-inline: auto;
}

.iconDeleteInvitation {
    color: var(--primary-color);
    cursor: pointer;
}

@media (min-width: 767px) {

    .iconEditNameClass:hover,
    .iconEditNameSchool:hover,
    .iconDeleteInvitation:hover {
        color: var(--secondary-color);
        transition: all 0.5s ease;
    }
  }