@import '../../App.css';

body {}

.divClassView {
    position: fixed;
    width: 100%;
}

.divClassViewFirstRow {
    margin-bottom: 10px;
    box-shadow: 0 10px 5px -10px var(--primary-color);
    display: grid;
}

.divClassViewFirstRow h1 {
    background-color: var(--secondary-color);
    border-radius: 5px;
    padding: 5px;
    color: var(--background-color);
}

.divClassViewFirstRowFirstCol {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 5px;
}
/*.divOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    box-shadow: 0 10px 10px -10px var(--primary-color);
}*/


.divOverflow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    overflow-y: scroll;
}


/* .cursorAddFruitApple {
    cursor: url("../../assets/fruits/apple.png") 50 50, auto;
}

.cursorAddFruitMelon {
    cursor: url("../../assets/fruits/melon.png") 50 50, auto;
} */

.divPickFruit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow-x: scroll;
    animation: showTransition 0.5s linear;
    
}

.imgFruitPicker {
    cursor: pointer;
}


.divPickFruit img {
    width: calc(var(--size-icon) + 10px);
    padding: 3px;

}

.divPickFruitImg {
    position: relative;
}


.pPickFruit{
    position: absolute;
    top: 50%;
    z-index: 1;
    text-align: center;
    left: 0;
    background-color: var(--secondary-color);
    padding: 2px;
    border-radius: 10px;
    font-size: calc(var(--font-size-body) - 2px);
    animation: showTransition 1s linear;
}

/* .divFruits {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divFruits>img {
    
} */



.selectedFruit {
    background-color: var(--secondary-color);
    border-radius: 50%;
}


.divViewAddStudents {
    text-align: center;
    
    animation: showTransition 0.5s linear;
}

.h2NoStudents {
    margin-top: 10px;
}

.iconOperation {
    font-size: var(--size-icon);
    color: var(--primary-color);
    transition: all 0.5s ease;
    cursor: pointer;
}
.iconOperationSelected {
    color: var(--secondary-color);
}

.divPickAward {
    padding: 10px;
    display: flex;
    align-items: top;
    justify-content: space-between;
    gap: 20px;
    margin-inline: 20px;
    width: calc(100% - 40px);
    overflow-x: scroll;
    animation: showTransition 0.5s linear;
}

.divAward {
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*box-shadow: 0 0 5px var(--primary-color);*/
    border-radius: 3px;
    padding: 10px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    border: 4px solid var(--secondary-color);
    background-color: var(--secondary-color);
}

.divAward h3 {
    cursor: pointer;
    color: var(--background-color);
    padding-top: 10px;
    text-align: center;
}

.divAwardSelected {
    background-color: #10db97;
    transition: all 0.5s ease;
    border-color:var(--primary-color);
}

.divAward .awardIcon {
    padding-top: 10px;
    font-size: 25px;
    margin-right: 5px;
    color: var(--background-color);

}

.divAward .awardPoints {
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    top: -20px;
    color: var(--background-color);
    font-weight: bolder;
    width: 18px;
    text-align: center;
    border: 3px solid #10db97;
    background-color: #10db97;
}

.divAwardSelected .awardTitle {
    color: var(--background-color);
    transition: all 0.5s ease;
}

.divAwardSelected .awardPoints {
    background-color: #10db97;
    transition: all 0.5s ease;
    border-color:var(--primary-color);
}

.addAwardIcon {
    margin-top: 10px;
    font-size: 20px;
    border: 2px solid var(--secondary-color);
    background-color: var(--secondary-color);
    border-radius: 50%;
    color: var(--background-color);
    padding: 5px;
    transition: all 0.5s ease;
}

.awardTypeIcon {
    position: absolute;
    bottom: 2px;
    left: 2px;
    color: white;
    font-size: 130%;
}

@keyframes showTransition {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .divOptions {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .divClassViewFirstRow {
        grid-template-columns: 1fr;
    }
}


/* Tablet and computer */
@media (min-width: 768px) {

    .iconOperation:hover {
        color: var(--secondary-color);
    }
   

    .divFruits>img:hover {
        background-color: var(--secondary-color);
        transition: background-color 0.5s ease;
        border-radius: 50%;
    }
    .divClassViewFirstRow {
        grid-template-columns: 1fr 1fr;
    }

    .divAward:hover {
        box-shadow: 0 0 10px var(--primary-color);
    }

    .addAwardIcon:hover {
        background-color: var(--primary-color);
        transition: all 0.5s ease;
        border-color: var(--primary-color);
    }

  }